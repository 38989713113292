import { useEffect, useState } from "react";
import Spinner from "../../components/Spinner";
import { serverEndpoint } from "../../util/config";
import { Link, useNavigate, useParams } from "react-router-dom";
import axios from "axios";
import { Card, Col, Row } from "react-bootstrap";
import { MdModeEdit, MdDelete } from "react-icons/md";
import ConfirmDialog from "../../components/ConfirmDialog";
import ErrorComponent from "../../components/Error";
import { useSelector } from 'react-redux';
import { hasPermission } from "../../util/permissionsEvaluator";
import NotFound404 from "../../components/NotFound404";

function ViewComponent() {
    const userDetails = useSelector((state) => state.userDetails);
    const navigate = useNavigate();
    const { componentId } = useParams();
    const [loading, setLoading] = useState(true);
    const [formLoading, setFormLoading] = useState(false);
    const [componentData, setComponentData] = useState(null);
    const [previewImageUrl, setPreviewImageUrl] = useState(null);

    const [isEdit, setIsEdit] = useState(false);
    const [formData, setFormData] = useState({});
    const [errors, setErrors] = useState({});
    const [message, setMessage] = useState(null);

    const handleDelete = () => {
        if (!hasPermission(userDetails, "Component Library", "Delete")) {
            setErrors({ message: 'You do not have permission to perform this action.' });
            return;
        }

        setShowConfirmDeleteModal(true);
    };

    const [showConfirmDeleteModal, setShowConfirmDeleteModal] = useState(false);
    const handleConfirmDeleteModalClose = (confirmed) => {
        if (confirmed) {
            setLoading(true);
            axios.post(`${serverEndpoint}/components/delete-component`, {
                component_id: componentId,
                image_key: componentData.image_key,
                cog: componentData.cog,
                instock_quantity: componentData.instock_quantity
            }, { withCredentials: true })
                .then(response => {
                    setShowConfirmDeleteModal(false);
                    setFormLoading(false);
                    navigate('/components');
                }).catch(error => {
                    console.log(error);
                    setErrors({ message: "Unable to delete component, try again!" });
                    setShowConfirmDeleteModal(false);
                    setFormLoading(false);
                });
        } else {
            setShowConfirmDeleteModal(false);
            setFormLoading(false);
        }
    };

    const handleEdit = () => {
        if (!hasPermission(userDetails, "Component Library", "Edit")) {
            setErrors({ message: 'You do not have permission to perform this action.' });
            return;
        }

        setFormData({
            title: componentData.title,
            description: componentData.description,
            instockQuantity: componentData.instock_quantity,
            componentImage: '',
            cog: componentData.cog ? componentData.cog : 0
        });
        setIsEdit(true);
    };

    const updateComponentData = (newComponentData, isUpdated) => {
        if (isUpdated) {
            setComponentData(newComponentData);
        }

        setIsEdit(false);
    };

    const validateForm = () => {
        let isValid = true;
        const newErrors = {};

        if (formData.title.length === 0) {
            newErrors.title = 'Please provide a valid Title';
            isValid = false;
        }

        if (formData.instockQuantity === undefined || formData.instockQuantity === '') {
            newErrors.instockQuantity = "In-stock quantity is required.";
            isValid = false;
        } else if (!Number.isInteger(Number(formData.instockQuantity)) || Number(formData.instockQuantity) < 0) {
            newErrors.instockQuantity = "In-stock quantity must be a non-negative integer.";
            isValid = false;
        }

        if (formData.cog !== '') {
            if (isNaN(Number(formData.cog)) || Number(formData.cog) < 0) {
                newErrors.cog = "COG must be a non-negative number.";
                isValid = false;
            }
        }

        setErrors(newErrors);
        return isValid;
    }

    const handleChange = (e) => {
        setFormData({
            ...formData,
            [e.target.name]: e.target.value
        });
    };

    const handleImageChange = (e) => {
        const file = e.target.files[0];

        if (file) {
            // Check file size (for example, 5MB limit)
            const maxSizeInBytes = 5 * 1024 * 1024;
            if (file.size > maxSizeInBytes) {
                setErrors({ ...errors, componentImage: 'File size should not exceed 5MB' });
                return;
            }

            // Clear previous errors if any
            setErrors({ ...errors, componentImage: null });

            // Set the file and create a preview URL
            setFormData({
                ...formData,
                componentImage: file,
            });

            // Generate and store the preview URL
            const preview = URL.createObjectURL(file);
            setPreviewImageUrl(preview);
        }
    };

    const handleSubmit = async (e) => {
        setMessage(null);
        e.preventDefault();
        if (validateForm()) {
            setFormLoading(true);
            try {
                let requestBody = {
                    title: formData.title,
                    description: formData.description === '' ? null : formData.description,
                    instock_quantity: formData.instockQuantity,
                    cog: formData.cog === '' ? 0 : formData.cog
                };

                if (formData.componentImage) {
                    setMessage('Uploading image...');
                    const uploadData = await axios.get(`${serverEndpoint}/images/get-upload-url/components/${componentId}`, {
                        params: {
                            contentType: formData.componentImage.type
                        },
                        withCredentials: true
                    });

                    const uploadUrl = uploadData.data.uploadURL;

                    await axios.put(uploadUrl, formData.componentImage, {
                        headers: {
                            'Content-Type': formData.componentImage.type
                        }
                    })
                    requestBody.image_key = uploadData.data.key;
                }

                await axios.patch(`${serverEndpoint}/components/update-component/${componentId}`, requestBody, { withCredentials: true });

                setMessage('Component Updated');
                setFormLoading(false);
                updateComponentData({
                    title: formData.title,
                    description: formData.description,
                    instock_quantity: formData.instockQuantity,
                    image_key: componentData.image_key,
                    image_url: componentData.image_url,
                    bundles: componentData.bundles,
                    cog: formData.cog
                }, true);
            } catch (error) {
                setErrors({
                    message: 'Something went wrong, please try again'
                })
                setFormLoading(false);
                console.log(error);
            }
        }
    };

    const getComponent = async () => {
        try {
            const response = await axios.get(`${serverEndpoint}/components/get-component/${componentId}`, {
                params: {
                    componentId: componentId,
                },
                withCredentials: true,
            });
            setComponentData(response.data);
            setLoading(false);
        } catch (error) {
            console.log(error);
            if (error.response?.data?.error?.includes('There is no component found')) {
                setErrors({ notFound: 'Component not found' });
            } else {
                setErrors({ fatal: 'Something went wrong, please try again' });
            }
            setLoading(false);
        }
    };

    useEffect(() => {
        getComponent();
    }, []);

    if (loading) {
        return <Spinner />;
    }

    if (errors?.fatal) {
        return <ErrorComponent />;
    }

    if (errors?.notFound) {
        return <NotFound404 />;
    }

    return (
        <div className="container">
            <div className="row mt-4 p-3 bg-white rounded border">
                {errors.message && (
                    <div className="alert alert-danger" role="alert">
                        {errors.message}
                    </div>
                )}
                {message && (
                    <div className="alert alert-success" role="alert">
                        {message}
                    </div>
                )}
                <h2 className="mb-3">
                    {isEdit ? (
                        <div>
                            <input id="title" type="text" className={errors?.title ? 'form-control is-invalid' : 'form-control'}
                                name="title" value={formData.title} required onChange={handleChange}
                            />
                            <div className="invalid-feedback">{errors?.title}</div>
                        </div>
                    ) : (
                        <>{componentData.title}</>
                    )}
                </h2>
                <div className="mb-3">
                    {!isEdit && (
                        <>
                            <button className="btn btn-outline-primary btn-sm me-2" onClick={handleEdit}>
                                <MdModeEdit className="mb-1" /> Edit
                            </button>
                            <button className="btn btn-outline-danger btn-sm" onClick={handleDelete}>
                                <MdDelete className="mb-1" /> Delete
                            </button>
                        </>
                    )}

                    {isEdit && (
                        <>
                            {formLoading ? (
                                <>
                                    <button className="btn btn-primary btn-sm me-2" type="button" disabled="">
                                        <span
                                            className="spinner-border spinner-border-sm"
                                            role="status"
                                            aria-hidden="true"
                                        />
                                    </button>
                                    <button className="btn btn-warning text-white btn-sm" type="button" disabled="">
                                        <span
                                            className="spinner-border spinner-border-sm"
                                            role="status"
                                            aria-hidden="true"
                                        />
                                    </button>
                                </>
                            ) : (
                                <>
                                    <button className="btn btn-primary btn-sm me-2" onClick={handleSubmit}>
                                        <MdModeEdit className="mb-1" /> Save
                                    </button>
                                    <button className="btn btn-warning text-white btn-sm" onClick={() => updateComponentData({}, false)}>
                                        <MdDelete className="mb-1" /> Close
                                    </button>
                                </>
                            )}

                        </>
                    )}

                </div>
                <div className="col-12 col-lg-3 mb-4">
                    {previewImageUrl ? (
                        <img src={previewImageUrl} alt="Preview" style={{
                            maxHeight: '500px',
                            width: '100%',
                            objectFit: 'cover'
                        }} />
                    ) : (
                        <div className="row">
                            <img src={componentData.image_url ? componentData.image_url : '/images/default-image.jpg'}
                                onError={(e) => {
                                    e.target.onerror = null; // Prevent infinite loop if fallback image fails
                                    e.target.src = "/images/default-image.jpg";
                                }}
                                alt={componentData.title} className="rounded" style={{
                                    maxHeight: '500px',
                                    width: '100%',
                                    objectFit: 'cover'
                                }} />
                        </div>
                    )}

                    {isEdit && (
                        <div className="row mt-1 mx-1">
                            <input id="componentImage" type="file" name="componentImage"
                                className={errors.componentImage ? 'form-control is-invalid' : 'form-control'}
                                onChange={handleImageChange} accept="image/*"
                            />
                            <div className="invalid-feedback">{errors?.componentImage}</div>
                        </div>
                    )}

                </div>
                <div className="col-12 col-lg-9">
                    <table className="table">
                        <tbody>
                            <tr>
                                <th className="w-25">Description</th>
                                <td>
                                    {isEdit ? (
                                        <div>
                                            <input id="description" type="text" className={errors?.description ? 'form-control is-invalid' : 'form-control'}
                                                name="description" value={formData.description} required onChange={handleChange}
                                            />
                                            <div className="invalid-feedback">{errors?.description}</div>
                                        </div>
                                    ) : (
                                        <>{componentData.description}</>
                                    )}
                                </td>
                            </tr>
                            <tr>
                                <th className="w-25">Instock Qty</th>
                                <td>
                                    {isEdit ? (
                                        <div>
                                            <input id="instockQuantity" type="number" min="0" className={errors?.instockQuantity ? 'form-control is-invalid' : 'form-control'}
                                                name="instockQuantity" value={formData.instockQuantity} required onChange={handleChange}
                                            />
                                            <div className="invalid-feedback">{errors?.instockQuantity}</div>
                                        </div>
                                    ) : (
                                        <>{componentData.instock_quantity}</>
                                    )}
                                </td>
                            </tr>
                            <tr>
                                <th className="w-25">COG (Cost of Good)</th>
                                <td>
                                    {isEdit ? (
                                        <div>
                                            <input id="cog" type="number" min="0" className={errors?.cog ? 'form-control is-invalid' : 'form-control'}
                                                name="cog" value={formData.cog} required onChange={handleChange}
                                            />
                                            <div className="invalid-feedback">{errors?.cog}</div>
                                        </div>
                                    ) : (
                                        <>{componentData.cog}</>
                                    )}
                                </td>
                            </tr>
                        </tbody>
                    </table>
                </div>
            </div >


            <div className="row mt-4 p-3 bg-white rounded border">
                <h3>Associated Bundles</h3>
                <div className="col">
                    {componentData.bundles && Object.keys(componentData.bundles).length > 0 ? (
                        <Row className="mt-4">
                            {Object.entries(componentData.bundles).map(([bundleId, bundle]) => (
                                <Col xs={12} md={6} lg={3} key={bundleId} className="mb-4">
                                    <Card className="h-100">
                                        <Link to={`/bundle/${bundleId}`}>
                                            <Card.Img
                                                variant="top"
                                                src={bundle.image_url ? bundle.image_url : '/images/default-image.jpg'}
                                                alt={bundle.title}
                                                style={{ height: '200px', objectFit: 'cover' }}
                                            />
                                        </Link>
                                        <Card.Body>
                                            <Link to={`/bundle/${bundleId}`} className="text-body text-decoration-none hover:text-primary">
                                                <Card.Title>{bundle.title}</Card.Title>
                                            </Link>
                                        </Card.Body>
                                    </Card>
                                </Col>
                            ))}
                        </Row>
                    ) : (
                        <p className="text-muted">No bundles available for this Component.</p>
                    )}
                </div>
            </div>

            <ConfirmDialog show={showConfirmDeleteModal} handleClose={handleConfirmDeleteModalClose}
                title="Delete Component Confirmation"
                message="Are you sure you want to delete the Component?"
            />
        </div >

    );
}

export default ViewComponent;