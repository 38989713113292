import axios from "axios";
import { useEffect, useState } from "react";
import Spinner from "../../components/Spinner";
import { serverEndpoint } from "../../util/config";
import ErrorComponent from "../../components/Error";
import Box from '@mui/material/Box';
import { DataGrid } from '@mui/x-data-grid';
import { Form } from "react-bootstrap";
import { createTheme, ThemeProvider } from '@mui/material/styles';
import { format } from 'date-fns';
import { Chip } from "@mui/material";
import ConfirmDialog from "../../components/ConfirmDialog";
import { Modal } from "react-bootstrap";
import { Link } from "react-router-dom";
import { useSelector } from 'react-redux';
import { hasPermission } from "../../util/permissionsEvaluator";
import { DEFAULT_PAGE_SIZE } from "../../util/constants";
import Tooltip from '@mui/material/Tooltip';

function ViewTasks() {
    const userDetails = useSelector((state) => state.userDetails);
    const [loading, setLoading] = useState(true);
    const [errors, setErrors] = useState({});
    const [message, setMessage] = useState(null);
    const [tasksData, setTasksData] = useState([]);
    const [displayData, setDisplayData] = useState([]);
    const [searchQuery, setSearchQuery] = useState("");
    const [paginationKey, setPaginationKey] = useState(null);
    const [itemsLoading, setItemsLoading] = useState(false);
    const [localDataAvailable, setLocalDataAvailable] = useState(false);
    const [page, setPage] = useState(0);
    const [pageSize, setPageSize] = useState(DEFAULT_PAGE_SIZE);
    const [filterValue, setFilterValue] = useState('none');
    const [selectedItems, setSelectedItems] = useState([]);
    const [isBulkPrepare, setIsBulkPrepare] = useState(false);
    const [isButtonDisabled, setIsButtonDisabled] = useState(true);
    const [isBulkUpdateInProgress, setIsBulkUpdateInProgress] = useState(false);
    const BATCH_SIZE = 20;

    const theme = createTheme({
        components: {
            MuiDataGrid: {
                styleOverrides: {
                    root: {
                        border: 'none',
                        overflowY: 'hidden',
                    },
                    columnHeaders: {
                        fontWeight: 'bold', // Apply bold to the entire header row container
                        '& .MuiDataGrid-columnHeaderTitle': {
                            fontWeight: 'bold', // Ensure individual header text is bold
                        },
                    },
                },
            },
        },
    });

    const handleCheckboxChange = (row, isChecked) => {
        const updatedRow = displayData.find((item) => item?.sk === row?.sk);

        if (isChecked) {
            const newItem = {
                item_id: updatedRow?.sk?.split('#')[3],
                sk: updatedRow.sk,
                quantity_to_prepare: updatedRow.quantity_to_prepare,
                updated_quantity_to_prepare: updatedRow.updated_quantity_to_prepare,
                profit: updatedRow.profit || 0,
                sale_price: updatedRow.sale_price || 0,
                available_quantity_to_prepare: updatedRow?.available_quantity_to_prepare,
            };

            setSelectedItems((prevSelectedItems) => {
                const updatedItems = [...prevSelectedItems, newItem];
                updateButtonDisabledState(updatedItems); // Check and update button state
                return updatedItems;
            });
        } else {
            const updatedSelectedItems = selectedItems.filter(
                (item) => item.item_id !== updatedRow?.sk?.split('#')[3]
            );
            setSelectedItems(updatedSelectedItems);
            updateButtonDisabledState(updatedSelectedItems); // Check and update button state
        }
    };

    const handleRowUpdate = (newRow) => {
        const { updated_quantity_to_prepare, available_quantity_to_prepare } = newRow;

        let qtyToPrepare = updated_quantity_to_prepare;

        // Function to check if the value is a valid number
        const isValidNumber = (str) => !isNaN(Number(str));

        // Validate if quantity_to_prepare is a valid number
        if (!isValidNumber(qtyToPrepare)) {
            qtyToPrepare = 0;
            newRow.updated_quantity_to_prepare = 0;
            newRow.error = true;
        }

        // Convert to number (in case it is a string) for further checks
        const parsedQuantity = Number(qtyToPrepare);

        // Validate the quantity input
        if (parsedQuantity < 1 || parsedQuantity > available_quantity_to_prepare) {
            // If the value is less than 0 or more than available quantity, set an error flag
            newRow.error = true;
        } else {
            // No error, set error flag to false
            newRow.error = false;
        }

        // Update the row state in DataGrid
        setDisplayData((prevRows) =>
            prevRows.map((row) =>
                row.sk === newRow.sk ? { ...row, updated_quantity_to_prepare: parsedQuantity, error: newRow.error } : row
            )
        );

        if (selectedItems?.some((item) => item.sk === newRow.sk)) {
            const updatedSelectedItems = selectedItems.map((item) =>
                item.sk === newRow.sk
                    ? { ...item, updated_quantity_to_prepare: parsedQuantity }
                    : item
            );
            setSelectedItems(updatedSelectedItems);
            updateButtonDisabledState(updatedSelectedItems); // Check and update button state
        }

        // Return the updated row to reflect the changes in the DataGrid
        return newRow;
    };

    const updateButtonDisabledState = (items) => {
        const valid = items.length > 0 && items.every(
            (item) =>
                item.updated_quantity_to_prepare > 0 &&
                item.updated_quantity_to_prepare <= item.available_quantity_to_prepare
        );
        setIsButtonDisabled(!valid);
    };

    const handleButtonClick = async () => {
        if (selectedItems.length === 0) {
            setIsBulkPrepare(true);
            setErrors({ message: 'Please select at least one item to proceed!' });
            return;
        }

        try {
            setIsBulkUpdateInProgress(true);
            const chunks = [];
            for (let i = 0; i < selectedItems.length; i += BATCH_SIZE) {
                chunks.push(selectedItems.slice(i, i + BATCH_SIZE));
            }

            let successCount = 0;
            for (let chunk of chunks) {
                const payload = chunk.map((selectedItem) => {
                    let updatedQuantityToPrepare = selectedItem.quantity_to_prepare - selectedItem.updated_quantity_to_prepare;
                    updatedQuantityToPrepare = updatedQuantityToPrepare < 0 ? 0 : updatedQuantityToPrepare;

                    return {
                        taskId: selectedItem?.sk?.split('#')[3],
                        status: 'OPEN', // Hardcoded for now
                        month_year: selectedItem?.sk?.split('#')[2] || 'current_month',
                        quantity_to_prepare: updatedQuantityToPrepare,
                        sale_price: selectedItem?.sale_price || 0,
                        profit: selectedItem?.profit || 0,
                    };
                });
                try {
                    await axios.patch(
                        `${serverEndpoint}/tasks/update-tasks`,
                        payload,
                        { withCredentials: true }
                    );
                    successCount += payload.length;
                } catch (error) {
                    console.log(error);
                    console.log('Continue with rest of the batches');
                }
            }

            if (successCount === 0) {
                setErrors({ message: 'We were unable to prepare any task at the moment. Please try again.' })
            } else if (successCount === selectedItems.length) {
                setMessage('Bulk prepare request succeeded!');
            } else {
                setErrors({ message: 'We were not able to prepare some of the tasks at the moment. Please try again.' })
            }
        } catch (error) {
            console.error('Unable to update tasks, try again!', error);
            setErrors({ message: 'Unable to update tasks, try again!' });
        } finally {
            await getTasks(true);
            setIsBulkUpdateInProgress(false);
        }
    };

    const columns = [
        {
            field: 'select',
            headerName: 'Select',
            width: 80,
            sortable: false,
            filterable: false,
            renderCell: (params) => {
                return (
                    <>
                        {params.row.available_quantity_to_prepare === 0 || params.row.error ? (
                            <div className="text-center mt-3">
                                <input
                                    type="checkbox"
                                    disabled
                                    className="form-check-input text-center"
                                />
                            </div>
                        ) : (
                            <div className="text-center mt-3">
                                <input
                                    type="checkbox"
                                    checked={selectedItems.some((item) => item.item_id === params.row?.sk?.split('#')[3])}
                                    onChange={(event) => handleCheckboxChange(params.row, event.target.checked)}
                                    className="form-check-input text-center"

                                />
                            </div>
                        )}
                    </>
                );

            },
        },
        { field: 'item_id', headerName: 'Item ID', width: 300, filterable: false },
        {
            field: 'title',
            headerName: 'Title',
            width: 350,
            editable: false,
            filterable: false,
            renderCell: (params) => {
                const splitedSk = params.row.item_id.split('#');
                let url;
                if (splitedSk[0] === 'B') {
                    url = `/bundle/${splitedSk[1]}`;
                } else if (splitedSk[0] === 'L') {
                    url = `/listing/${splitedSk[1]}`;
                } else if (splitedSk[0] === 'C') {
                    url = `/component/${splitedSk[1]}`;
                } else {
                    url = '#';
                }

                return (
                    <>
                        <Link className="mx-1" to={url}>
                            <img src={params.row.image_url ? params.row.image_url : '/images/default-image.jpg'} 
                                onError={(e) => {
                                    e.target.onerror = null; // Prevent infinite loop if fallback image fails
                                    e.target.src = "/images/default-image.jpg";
                                }}
                                alt={params.row.title} className="rounded-1" style={{
                                    maxHeight: '30px'
                                }} />
                        </Link>
                        <Link className="mx-1 text-body text-decoration-none hover:text-primary" to={url}>{params.row.title}</Link>
                    </>
                );
            }
        },
        {
            field: 'creation_date',
            headerName: 'Creation Date',
            width: 130,
            editable: false,
            filterable: false,
            renderCell: (params) => {
                return (
                    <div>
                        {format(new Date(params.row.creation_date), 'MMM d, yyyy')}
                    </div>
                );
            }
        },
        {
            field: 'updated_quantity_to_prepare',
            headerName: 'Qty to Prepare',
            editable: true,
            isCellEditable: (params) => !params.row.available_quantity_to_prepare || params.row.available_quantity_to_prepare > 0,
            width: 130,
            filterable: false,
            renderCell: (params) => {
                const isEditable = params.row.available_quantity_to_prepare > 0;
                const isError = params.row.error && isEditable; // Check if the row has an error
                const tooltipMessage = isError
                    ? `The quantity must be between 0 and ${params.row.available_quantity_to_prepare}.`
                    : ''; // Set error message if there's an issue

                return (
                    <>
                        {isError && (
                            <>
                                {isEditable ? (
                                    <Tooltip title={tooltipMessage || 'Enter a valid quantity'} placement="top" arrow>
                                        <input
                                            type="number"
                                            value={params.row.updated_quantity_to_prepare}
                                            onChange={(e) => handleRowUpdate({ ...params.row, updated_quantity_to_prepare: e.target.value })}
                                            //style={inputStyle}
                                            className="form-control mt-2 rounded-1 text-center"
                                            style={{
                                                fontSize: '14px',
                                                color: '#ed6c02',
                                                border: '1px solid #ed6c02'
                                            }}
                                        />
                                    </Tooltip>
                                ) : (
                                    <div className="text-center">{params.row.updated_quantity_to_prepare}</div>
                                )}
                            </>
                        )}

                        {!isError && (
                            <>
                                {isEditable ? (
                                    <input
                                        type="number"
                                        value={params.row.updated_quantity_to_prepare}
                                        onChange={(e) => handleRowUpdate({ ...params.row, updated_quantity_to_prepare: e.target.value })}
                                        //style={inputStyle}
                                        className="form-control mt-2 rounded-1 text-center"
                                        style={{
                                            fontSize: '14px'
                                        }}
                                    />
                                ) : (
                                    <div className="text-center pe-3">{params.row.updated_quantity_to_prepare}</div>
                                )}
                            </>
                        )}
                    </>
                );
            },
        },
        {
            field: 'available_quantity_to_prepare',
            headerName: 'Qty Available',
            width: 130,
            editable: false,
            filterable: false,
            renderCell: (params) => {
                return (
                    <div>
                        {params.row.available_quantity_to_prepare ? params.row.available_quantity_to_prepare : 0}
                    </div>
                );
            }
        },
        {
            field: 'prepare',
            headerName: 'Prepare',
            width: 120,
            editable: false,
            filterable: false,
            sortable: false,
            renderCell: (params) => {
                const { available_quantity_to_prepare, updated_quantity_to_prepare } = params.row;
                let color;
                let displayText;

                if (!available_quantity_to_prepare || available_quantity_to_prepare === 0) {
                    displayText = 'Out of Stock';
                    color = 'warning';
                } else if (available_quantity_to_prepare >= updated_quantity_to_prepare) {
                    displayText = 'Prepare';
                    color = 'success';
                } else {
                    displayText = 'Prepare';
                    color = 'warning';
                }

                return (
                    <Chip label={displayText} disabled={!available_quantity_to_prepare || available_quantity_to_prepare === 0 ? true : false} color={color} variant="outlined" clickable onClick={() => handleTaskUpdate(params.row)} />
                );
            }
        },
        {
            field: 'delete',
            headerName: 'Delete',
            width: 100,
            editable: false,
            filterable: false,
            sortable: false,
            renderCell: (params) => (
                <Chip label="Delete" className="mx-2" color='warning' variant="outlined" clickable onClick={() => handleTaskDelete(params.row)} />
            )
        }
    ];

    const [markPrepareLoading, setMarkPrepareLoading] = useState(false);
    const [showPrepareModal, setShowPrepareModal] = useState(false);
    const [prepareFormData, setPrepareFormData] = useState({
        quantityPrepared: 0
    });

    const handlePrepareModalClose = () => setShowPrepareModal(false);
    const handleTaskUpdate = (task) => {
        if (!hasPermission(userDetails, "Task Management", "Edit")) {
            setErrors({ message: 'You do not have permission to perform this action.' });
            return;
        }

        setErrors({});
        setPrepareFormData({
            quantityPrepared: task.available_quantity_to_prepare >= task.quantity_to_prepare ? task.quantity_to_prepare : task.available_quantity_to_prepare,
            taskId: task.sk.split('#')[3],
            status: 'OPEN',
            monthYear: task.sk.split('#')[2],
            availableQuantityToPrepare: task.available_quantity_to_prepare,
            quantityToPrepare: task.quantity_to_prepare,
            profit: task.profit,
            salePrice: task.sale_price
        });
        setShowPrepareModal(true);
    }

    const handleChange = (e) => {
        const { name, value } = e.target;
        setPrepareFormData({
            ...prepareFormData,
            [name]: value,
        });
    };

    const handleSubmit = async (e) => {
        e.preventDefault();
        if (prepareFormData.quantityPrepared <= 0 || prepareFormData.quantityPrepared > prepareFormData.availableQuantityToPrepare) {
            setErrors({
                quantityPrepared: `Quantity Prepared must be between 1 and ${prepareFormData.availableQuantityToPrepare}`
            });
            return;
        }

        setMarkPrepareLoading(true);

        try {
            let updatedQuantityToPrepare = prepareFormData.quantityToPrepare - prepareFormData.quantityPrepared;
            updatedQuantityToPrepare = updatedQuantityToPrepare < 0 ? 0 : updatedQuantityToPrepare;
            await axios.patch(`${serverEndpoint}/tasks/update-task/${prepareFormData.taskId}`, {
                quantity_to_prepare: updatedQuantityToPrepare,
                profit: prepareFormData.profit,
                sale_price: prepareFormData.salePrice
            }, {
                params: {
                    status: prepareFormData.status,
                    month_year: prepareFormData.monthYear
                },
                withCredentials: true
            });

            await getTasks(true);

            setMarkPrepareLoading(false);
        } catch (error) {
            setErrors({ message: 'Unable to update task, try again!' })
            setMarkPrepareLoading(false);
        }

        handlePrepareModalClose();
    };


    const [selectedTask, setSelectedTask] = useState(null);
    const [showConfirmDeleteModal, setShowConfirmDeleteModal] = useState(false);

    const handleTaskDelete = (task) => {
        if (!hasPermission(userDetails, "Task Management", "Delete")) {
            setErrors({ message: 'You do not have permission to perform this action.' });
            return;
        }

        setSelectedTask(task);
        setShowConfirmDeleteModal(true);
    };

    const handleConfirmDeleteModalClose = (confirmed) => {
        if (confirmed) {
            axios.delete(`${serverEndpoint}/tasks/delete-task/${selectedTask.sk.split('#')[3]}`, {
                params: {
                    status: selectedTask.sk.split('#')[1],
                    month_year: selectedTask.sk.split('#')[2]
                },
                withCredentials: true
            })
                .then(response => {
                    setShowConfirmDeleteModal(false);
                    setSelectedTask(null);
                    const updatedTasks = tasksData.filter(t => t.sk !== selectedTask.sk);
                    setTasksData(updatedTasks);
                }).catch(error => {
                    setErrors({ message: "Unable to delete task, try again!" });
                    setShowConfirmDeleteModal(false);
                });
        } else {
            setShowConfirmDeleteModal(false);
        }
    };

    const getTasks = async (reset = false) => {
        if (!reset) {
            if (itemsLoading || localDataAvailable) return;
        }

        try {
            setItemsLoading(true);
            const params = {
                limit: DEFAULT_PAGE_SIZE,
            };

            if (!reset && paginationKey) {
                params.lastEvaluatedKey = paginationKey;
            }

            const response = await axios.get(`${serverEndpoint}/tasks/get-tasks/OPEN`, {
                params: params,
                withCredentials: true,
            });
            setTasksData(!reset
                ? (prev) => [
                    ...prev,
                    ...response.data.items.map(item => ({
                        ...item,
                        updated_quantity_to_prepare: item.quantity_to_prepare,
                        error: (item.quantity_to_prepare > item.available_quantity_to_prepare) ? true : false
                    }))
                ]
                : response.data.items.map(item => ({
                    ...item,
                    updated_quantity_to_prepare: item.quantity_to_prepare,
                    error: (item.quantity_to_prepare > item.available_quantity_to_prepare) ? true : false
                }))
            );
            setDisplayData(!reset
                ? (prev) => [
                    ...prev,
                    ...response.data.items.map(item => ({
                        ...item,
                        updated_quantity_to_prepare: item.quantity_to_prepare,
                        error: (item.quantity_to_prepare > item.available_quantity_to_prepare) ? true : false
                    }))
                ]
                : response.data.items.map(item => ({
                    ...item,
                    updated_quantity_to_prepare: item.quantity_to_prepare,
                    error: (item.quantity_to_prepare > item.available_quantity_to_prepare) ? true : false
                }))
            );
            setPaginationKey(response.data.lastEvaluatedKey);
            if (!response.data.lastEvaluatedKey) {
                setLocalDataAvailable(true);
            } else {
                setLocalDataAvailable(false);
            }
            setLoading(false);
        } catch (error) {
            console.log(error);
            setErrors({ fatal: 'Something went wrong, please try again!' });
            setLoading(false);
        } finally {
            setItemsLoading(false);
        }
    };

    useEffect(() => {
        getTasks();
    }, [page]);

    const getSearchResults = async () => {
        try {
            setItemsLoading(true);
            const params = {
                limit: DEFAULT_PAGE_SIZE,
                searchString: searchQuery
            };
            if (paginationKey) {
                params.lastEvaluatedKey = paginationKey;
            }

            const response = await axios.get(`${serverEndpoint}/tasks/get-tasks/OPEN`, {
                params: params,
                withCredentials: true,
            });
            setDisplayData(response.data.items.map(item => ({
                ...item,
                updated_quantity_to_prepare: item.quantity_to_prepare,
                error: (item.quantity_to_prepare > item.available_quantity_to_prepare) ? true : false
            })));
            setLoading(false);
        } catch (error) {
            console.log(error);
            setErrors({ fatal: 'Something went wrong, please try again!' });
            setLoading(false);
        } finally {
            setItemsLoading(false);
        }
    };

    useEffect(() => {
        const delayDebounceFn = setTimeout(() => {
            if (searchQuery.trim() === "") {
                setDisplayData([...tasksData]); // Reset to full data if search term is empty
            } else {
                const searchResults = paginationKey === null
                    ? tasksData.filter((item) =>
                        item.title?.toLowerCase().includes(searchQuery.toLowerCase())
                    ) // Local search
                    : null;

                if (searchResults !== null) {
                    setDisplayData(searchResults);
                } else {
                    getSearchResults();
                }
            }
        }, 500);

        return () => clearTimeout(delayDebounceFn);
    }, [searchQuery, tasksData]);

    const handlePageChange = (newPage) => {
        setPage(newPage);
    };

    const handleFilterChange = (event) => {
        const selectedFilter = event.target.value;
        setFilterValue(selectedFilter);

        let filteredData;
        switch (selectedFilter) {
            case 'ready':
                filteredData = tasksData.filter(
                    (row) => row.quantity_to_prepare <= row.available_quantity_to_prepare
                );
                break;
            case 'partially-ready':
                filteredData = tasksData.filter(
                    (row) =>
                        row.quantity_to_prepare > row.available_quantity_to_prepare &&
                        row.available_quantity_to_prepare > 0
                );
                break;
            case 'out-of-stock':
                filteredData = tasksData.filter((row) => !row.available_quantity_to_prepare || row.available_quantity_to_prepare === 0);
                break;
            default:
                filteredData = tasksData;
        }

        setDisplayData(filteredData);
    };

    if (loading) {
        return <Spinner />;
    }

    if (errors?.fatal) {
        return <ErrorComponent />;
    }

    return (
        <>
            <section>
                <div className="container bg-white p-4 rounded border">
                    <div className="row align-items-center">
                        {errors.message && (
                            <div className={`alert ${isBulkPrepare ? 'alert-warning' : 'alert-danger'} `} role="alert">
                                {errors.message}
                            </div>
                        )}
                        {message && (
                            <div className="alert alert-success" role="alert">
                                {message}
                            </div>
                        )}
                        {/* Left Section: Title and Subtitle */}
                        <div className="col">
                            <h2 className="mb-0">Tasks</h2>
                            <p className="text-secondary mb-0">View & Manage all your Tasks</p>
                        </div>

                        {/* Right Section: Button */}
                        <div className="col-auto">
                            {isBulkUpdateInProgress && (
                                <button className="btn btn-primary btn-sm" type="button" disabled="">
                                    <span
                                        className="spinner-border spinner-border-sm"
                                        role="status"
                                        aria-hidden="true"
                                    />
                                </button>
                            )}

                            {!isBulkUpdateInProgress && (
                                <button
                                    className="btn btn-primary btn-sm"
                                    type="button"
                                    onClick={handleButtonClick}
                                    disabled={isButtonDisabled}>
                                    Bulk Prepare
                                </button>
                            )}
                        </div>
                    </div>
                    <hr className="text-muted" />
                    <div className="my-3 text-center">
                        <Form.Control
                            type="text"
                            placeholder="Search by title or Description"
                            value={searchQuery}
                            onChange={(e) => setSearchQuery(e.target.value)}
                        />
                    </div>

                    <div style={{ display: 'flex', flexDirection: 'column' }}>
                        <ThemeProvider theme={theme}>
                            <Box>
                                <div className="form-floating">
                                    <select className="form-control form-control-sm"
                                        id="filter" name="filter"
                                        value={filterValue}
                                        onChange={handleFilterChange}
                                    >
                                        <option value='none'>None</option>
                                        <option value='ready'>Ready</option>
                                        <option value='partially-ready'>Partially Ready</option>
                                        <option value='out-of-stock'>Out of Stock</option>
                                    </select>
                                    <label htmlFor="filter">Filter by Prepare Status</label>
                                </div>


                                <DataGrid
                                    initialState={{
                                        columns: {
                                            columnVisibilityModel: {
                                                item_id: false
                                            }
                                        },
                                        pagination: {
                                            paginationModel: { page: 0, pageSize: DEFAULT_PAGE_SIZE }, // Set initial state explicitly
                                        },
                                    }}
                                    rows={displayData.slice(page * pageSize, (page + 1) * pageSize)}
                                    getRowId={(row) => row.sk}
                                    rowCount={localDataAvailable ? displayData.length : -1}
                                    columns={columns}
                                    disableRowSelectionOnClick
                                    showCellVerticalBorder={true}
                                    showColumnVerticalBorder={true}
                                    pagination
                                    paginationMode="server"
                                    pageSize={pageSize}
                                    page={page} // Controlled page
                                    rowsPerPageOptions={[DEFAULT_PAGE_SIZE]}
                                    onPaginationModelChange={({ page }) => {
                                        handlePageChange(page);
                                    }}
                                    loading={loading || itemsLoading}
                                    sx={{
                                        minHeight: displayData.length === 0 ? 400 : 'auto',
                                        '& .MuiDataGrid-overlay': {
                                            backgroundColor: '#ffffff',
                                        },
                                    }}
                                    localeText={{
                                        noRowsLabel: "No data available to display",
                                    }}
                                    processRowUpdate={handleRowUpdate}
                                />
                            </Box>
                        </ThemeProvider>
                    </div>
                </div>
            </section>

            <ConfirmDialog show={showConfirmDeleteModal} handleClose={handleConfirmDeleteModalClose}
                title="Delete Task Confirmation"
                message="Are you sure you want to delete the Task?"
            />

            <Modal show={showPrepareModal} onHide={handlePrepareModalClose} centered>
                <Modal.Header closeButton>
                    <Modal.Title>Enter Quantity Prepared</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <form onSubmit={handleSubmit}>
                        <div className="mb-3">
                            <label className="mb-2 text-muted" htmlFor="quantityPrepared">
                                Quantity Prepared
                            </label>
                            <input id="quantityPrepared" type="number" className={errors?.quantityPrepared ? 'form-control is-invalid' : 'form-control'}
                                name="quantityPrepared" value={prepareFormData.quantityPrepared} required autoFocus onChange={handleChange}
                            />
                            <div className="invalid-feedback">{errors.quantityPrepared}</div>
                        </div>

                        <div className="align-items-center d-flex">
                            {markPrepareLoading && (
                                <button className="btn btn-primary ms-auto" type="button" disabled>
                                    <span
                                        className="spinner-border spinner-border-sm"
                                        role="status"
                                        aria-hidden="true"
                                    />
                                </button>
                            )}

                            {!markPrepareLoading && (
                                <button className="btn btn-primary ms-auto" type="submit">
                                    Submit
                                </button>
                            )}
                        </div>
                    </form>
                </Modal.Body>
            </Modal>
        </>
    );
}

export default ViewTasks;
