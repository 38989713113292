import { FaRegCheckCircle } from "react-icons/fa";
import { Link } from "react-router-dom";
import { useDispatch } from "react-redux";
import { useEffect, useState } from "react";
import { useNavigate } from 'react-router-dom';

function PaymentSuccess({ userDetails }) {
    const navigate = useNavigate();
    const dispatch = useDispatch();
    const [secondsLeft, setSecondsLeft] = useState(5);

    useEffect(() => {
        if (userDetails) {
            userDetails.isFirstLogin = userDetails.featureFlags.welcome_notifications ? true : false;
            dispatch({ type: 'setUserDetails', userDetails: userDetails });
        }

        const timer = setInterval(() => {
            setSecondsLeft((prev) => prev - 1); // Decrease seconds
        }, 1000);

        // Redirect when timer hits 0
        if (secondsLeft === 0) {
            clearInterval(timer);
            navigate('/dashboard'); // Redirect to dashboard
        }

        return () => clearInterval(timer); // Cleanup timer
    }, [secondsLeft, navigate]);

    return (
        <div className="row justify-content-center mt-7">
            <div className="col-lg-5 text-center">
                <div className="card mt-5">
                    <div className="card-body py-5 px-lg-5">
                        <FaRegCheckCircle className="text-success" size={80} />
                        {userDetails && (
                            <p className="mt-4 mb-1">
                                Your payment has been processed successfully. You will be redirected to your dashboard in{' '}
                                <strong>{secondsLeft} second{secondsLeft !== 1 ? 's' : ''}</strong>.
                                <br />
                                If you're not redirected automatically, click {' '}<Link className="btn-link" to="/dashboard">here</Link>{' '} to proceed.
                            </p>
                        )}

                        {!userDetails && (
                            <p className="mt-4 mb-1">
                                Your payment has been processed successfully.
                                You will be redirected to signin page in 5 seconds.
                                <br />
                                If you're not redirected automatically, click {' '}<Link className="btn-link" to="/signin">here</Link>{' '} to proceed.
                            </p>
                        )}

                    </div>
                </div>
            </div>
        </div>
    );
}

export default PaymentSuccess;