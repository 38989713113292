import { useState } from "react";
import { FaLock } from "react-icons/fa6";
import axios from 'axios';
import { serverEndpoint } from "../../util/config";

function MultiFactorAuthentication({ userDetails, updateUserDetails }) {
    const [loading, setLoading] = useState(false);
    const [totpCode, setTotpCode] = useState('');
    const [message, setMessage] = useState(null);

    const handleChange = (e) => {
        const inputValue = e.target.value;

        // Allow only numbers and limit length to 6
        if (/^\d*$/.test(inputValue) && inputValue.length <= 6) {
            setTotpCode(inputValue);
        }
    };

    const handleSubmit = async (e) => {
        setLoading(true);
        e.preventDefault();
        axios.post(`${serverEndpoint}/auth/signin`, {
            session: userDetails.session,
            email: userDetails.username,
            totpCode: totpCode
        }, { withCredentials: true })
            .then(response => {
                setLoading(false);
                const userDetails = response.data.user;
                userDetails.isFirstLogin = userDetails.featureFlags.welcome_notifications ? true : false;
                updateUserDetails(userDetails);
            }).catch(error => {
                if (error.response.data.error === 'Invalid code received for user') {
                    setMessage("Authentication failed, Invalid Code");
                } else {
                    setMessage("Something went wrong, please try again.");
                }
                setLoading(false);
            });
    };

    return (
        <div className="row justify-content-center mt-7">
            <div className="col-lg-4 text-center">
                <div className="card mt-5">
                    <div className="card-body py-5 px-lg-5">
                        <div>
                            {message && (
                                <div className="alert alert-danger" role="alert">
                                    {message}
                                </div>
                            )}
                        </div>
                        <FaLock size={32} />
                        <h3 className="fw-normal text-dark mt-4">Authentication Code</h3>
                        <p className="mt-4 mb-1">
                            Open your two-factor authenticator (TOTP) app or browser extension to view your authentication code.
                        </p>
                        <form onSubmit={handleSubmit}>
                            <div className="row mt-4 pt-2">
                                <div className="col">
                                    <input
                                        type="text"
                                        className="form-control text-center py-2"
                                        maxLength={6}
                                        minLength={6}
                                        placeholder="XXX XXX"
                                        autoFocus
                                        value={totpCode}
                                        onChange={handleChange}
                                    />
                                </div>
                            </div>
                            {loading && (
                                <button className="btn btn-success btn-lg w-100 mt-4" type="button" disabled="">
                                    <span
                                        className="spinner-border spinner-border-sm"
                                        role="status"
                                        aria-hidden="true"
                                    />
                                </button>
                            )}

                            {!loading && (
                                <button className="btn btn-success btn-lg w-100 mt-4" type="submit">
                                    Verify
                                </button>
                            )}
                        </form>
                    </div>
                </div>
            </div>
        </div>
    );
}

export default MultiFactorAuthentication;