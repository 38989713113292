import { useEffect, useState, useCallback } from "react";
import Spinner from "../../components/Spinner";
import { CURRENCY_TO_SYMBOL_MAP, serverEndpoint } from "../../util/config";
import { Link, useNavigate, useParams } from "react-router-dom";
import axios from "axios";
import { Card, Col, Row } from "react-bootstrap";
import { MdModeEdit, MdDelete } from "react-icons/md";
import ConfirmDialog from "../../components/ConfirmDialog";
import { IoMdSave } from "react-icons/io";
import AsyncSelect from 'react-select/async';
import ErrorComponent from "../../components/Error";
import { useSelector } from 'react-redux';
import { hasPermission } from "../../util/permissionsEvaluator";
import NotFound404 from "../../components/NotFound404";

const CustomOption = ({ innerRef, innerProps, data }) => (
    <div ref={innerRef} {...innerProps} className='my-1' style={{ display: 'flex', alignItems: 'center' }}>
        <img
            src={data.imageUrl}
            alt={data.label}
            style={{ width: 30, height: 30, marginRight: 10 }}
        />
        {data.label}
    </div>
);

function ViewBundle() {
    const userDetails = useSelector((state) => state.userDetails);
    const currencySymbol = CURRENCY_TO_SYMBOL_MAP[userDetails.accountCurrency] || '£';
    const navigate = useNavigate();
    const { bundleId } = useParams();
    const [loading, setLoading] = useState(true);
    const [formLoading, setFormLoading] = useState(false);
    const [bundleData, setBundleData] = useState(null);
    const [previewImageUrl, setPreviewImageUrl] = useState(null);
    const [timeoutId, setTimeoutId] = useState(null);

    const [isEdit, setIsEdit] = useState(false);
    const [formData, setFormData] = useState({});
    const [errors, setErrors] = useState({});
    const [message, setMessage] = useState(null);

    const handleDelete = () => {
        if (!hasPermission(userDetails, "Bundles", "Delete")) {
            setErrors({ message: 'You do not have permission to perform this action.' });
            return;
        }

        setShowConfirmDeleteModal(true);
    };

    const [showConfirmDeleteModal, setShowConfirmDeleteModal] = useState(false);
    const handleConfirmDeleteModalClose = (confirmed) => {
        if (confirmed) {
            setLoading(true);
            axios.post(`${serverEndpoint}/bundles/delete-bundle`, {
                bundle_id: bundleId,
                image_key: bundleData.image_key
            }, { withCredentials: true })
                .then(response => {
                    setShowConfirmDeleteModal(false);
                    setFormLoading(false);
                    navigate('/bundles');
                }).catch(error => {
                    setErrors({ message: "Unable to delete bundle, try again!" });
                    setShowConfirmDeleteModal(false);
                    setFormLoading(false);
                });
        } else {
            setShowConfirmDeleteModal(false);
            setFormLoading(false);
        }
    };

    const handleEdit = () => {
        if (!hasPermission(userDetails, "Bundles", "Edit")) {
            setErrors({ message: 'You do not have permission to perform this action.' });
            return;
        }

        const components = Object.entries(bundleData.components).map(([componentId, component]) => ({
            value: componentId,
            label: component.title,
            imageKey: component.image_key,
            imageUrl: component.image_url,
            quantity: component.quantity,
        }));

        const listings = Object.entries(bundleData.listings).map(([listingId, listing]) => ({
            value: listingId,
            label: listing.title,
            imageKey: listing.image_key,
            imageUrl: listing.image_url
        }));

        setFormData({
            title: bundleData.title,
            description: bundleData.description,
            instockQuantity: bundleData.instock_quantity,
            bundleImage: '',
            imageUrl: '',
            imageKey: '',
            cog: bundleData.cog,
            roi: bundleData.roi,
            margin: bundleData.margin,
            profit: bundleData.profit,
            salePrice: bundleData.sale_price,
            listings: listings,
            components: components
        });
        setIsEdit(true);
    };

    const updateBundleData = (newBundleData, isUpdated) => {
        if (isUpdated) {
            setBundleData(newBundleData);
        }

        setIsEdit(false);
    };

    const validateForm = () => {
        let isValid = true;
        const newErrors = {};

        if (formData.title === '') {
            newErrors.title = "Title is required.";
            isValid = false;
        }

        if (formData.instockQuantity === undefined || formData.instockQuantity === '') {
            newErrors.instockQuantity = "In-stock quantity is required.";
            isValid = false;
        } else if (!Number.isInteger(Number(formData.instockQuantity)) || Number(formData.instockQuantity) < 0) {
            newErrors.instockQuantity = "In-stock quantity must be a non-negative integer.";
            isValid = false;
        }

        const floatFields = ['salePrice', 'cog', 'margin', 'roi', 'profit'];

        floatFields.forEach((field) => {
            if (formData[field] === undefined || formData[field] === '') {
                newErrors[field] = `${field.replace('_', ' ')} is required.`;
                isValid = false;
            } else if (isNaN(formData[field]) || Number(formData[field]) < 0) {
                newErrors[field] = `${field.replace('_', ' ')} must be a non-negative number.`;
                isValid = false;
            } else {
                const floatValue = parseFloat(formData[field]);
                if (isNaN(floatValue)) {
                    newErrors[field] = `${field.replace('_', ' ')} must be a valid number.`;
                    isValid = false;
                }
            }
        });

        if (formData.components.length > 0) {
            formData.components.forEach((component) => {
                if (!component.quantity || component.quantity <= 0) {
                    newErrors.components = newErrors.components || {}; // Initialize if not already
                    newErrors.components[component.value] = 'Quantity must be greater than 0';
                    isValid = false;
                } else if (newErrors.components) {
                    // Remove error if valid to avoid retaining old errors
                    delete newErrors.components[component.value];
                }
            });
        }

        setErrors(newErrors);
        return isValid;
    }

    const handleChange = (e) => {
        const { name, value } = e.target;

        setFormData((prevData) => {
            const updatedData = { ...prevData, [name]: value };

            // Calculate ROI, Margin if salePrice or cog or profit changes
            if (name === "salePrice" || name === "cog" || name === "profit") {
                const salePrice = parseFloat(updatedData.salePrice) || 0;
                const cog = parseFloat(updatedData.cog) || 0;
                const profit = parseFloat(updatedData.profit) || 0;

                // Calculate Margin, ROI
                const margin = salePrice > 0 ? (profit / salePrice) * 100 : 0;
                const roi = cog > 0 ? (profit / cog) * 100 : 0;

                // Update the calculated fields
                updatedData.margin = margin.toFixed(2);
                updatedData.roi = roi.toFixed(2);
            }

            return updatedData;
        });
    };

    const handleImageChange = (e) => {
        const file = e.target.files[0];

        if (file) {
            // Check file size (for example, 5MB limit)
            const maxSizeInBytes = 5 * 1024 * 1024;
            if (file.size > maxSizeInBytes) {
                setErrors({ ...errors, bundleImage: 'File size should not exceed 5MB' });
                return;
            }

            // Clear previous errors if any
            setErrors({ ...errors, bundleImage: null });

            // Set the file and create a preview URL
            setFormData({
                ...formData,
                imageUrl: '',
                imageKey: '',
                bundleImage: file,
            });

            // Generate and store the preview URL
            const preview = URL.createObjectURL(file);
            setPreviewImageUrl(preview);
        }
    };

    const handleListingChange = (selected) => {
        setFormData({
            ...formData,
            title: selected.label,
            salePrice: selected.salePrice,
            imageKey: selected.imageKey,
            imageUrl: selected.imageUrl,
            listings: [selected]
        });
        setPreviewImageUrl(selected.imageUrl);
    };

    const handleComponentsChange = (selected) => {
        setFormData({
            ...formData,
            components: selected
        });
    };

    const handleSubmit = async (e) => {
        setMessage(null);
        e.preventDefault();
        if (validateForm()) {
            setFormLoading(true);
            const transformedListings = formData.listings.reduce((acc, item) => {
                acc[item.value] = {
                    title: item.label,
                    image_key: item.imageKey
                };
                return acc;
            }, {});

            const transformedComponents = formData.components.reduce((acc, item) => {
                acc[item.value] = {
                    title: item.label,
                    image_key: item.imageKey,
                    quantity: item.quantity
                };
                return acc;
            }, {});

            const requestBody = {
                title: formData.title,
                description: formData.description,
                instock_quantity: formData.instockQuantity,
                cog: formData.cog,
                roi: formData.roi,
                margin: formData.margin,
                profit: formData.profit,
                sale_price: formData.salePrice,
                listings: transformedListings,
                components: transformedComponents
            };

            if (formData.bundleImage) {
                setMessage('Uploading image...');
                const uploadData = await axios.get(`${serverEndpoint}/images/get-upload-url/bundles`, {
                    params: {
                        contentType: formData.bundleImage.type
                    },
                    withCredentials: true
                });

                const uploadUrl = uploadData.data.uploadURL;

                await axios.put(uploadUrl, formData.bundleImage, {
                    headers: {
                        'Content-Type': formData.bundleImage.type
                    }
                })
                requestBody.image_key = uploadData.data.key;
            } else if (formData.imageKey.length > 0) {
                requestBody.image_key = formData.imageKey;
            }

            try {
                await axios.patch(`${serverEndpoint}/bundles/update-bundle/${bundleId}`, requestBody, { withCredentials: true });
                setMessage('Bundle Updated!');
                setFormLoading(false);

                const uiTransformedListings = formData.listings.reduce((acc, item) => {
                    acc[item.value] = {
                        title: item.label,
                        image_key: item.imageKey,
                        image_url: item.imageUrl
                    };
                    return acc;
                }, {});

                const uiTransformedComponents = formData.components.reduce((acc, item) => {
                    acc[item.value] = {
                        title: item.label,
                        image_key: item.imageKey,
                        image_url: item.imageUrl,
                        quantity: item.quantity
                    };
                    return acc;
                }, {});
                updateBundleData({
                    title: formData.title,
                    description: formData.description,
                    instock_quantity: formData.instockQuantity,
                    cog: formData.cog,
                    roi: formData.roi,
                    margin: formData.margin,
                    profit: formData.profit,
                    sale_price: formData.salePrice,
                    listings: uiTransformedListings,
                    components: uiTransformedComponents
                }, true);
            } catch (error) {
                setErrors({
                    message: 'Something went wrong, please try again'
                })
                setFormLoading(false);
                console.log(error);
            }
        }
    };

    const getBundle = async () => {
        try {
            const response = await axios.get(`${serverEndpoint}/bundles/get-bundle/${bundleId}`, {
                params: {
                    bundleId: bundleId,
                },
                withCredentials: true,
            });
            setBundleData(response.data);
        } catch (error) {
            console.log(error);
            if (error.response?.data?.error?.includes('There is no bundle found')) {
                setErrors({ notFound: 'Bundle not found' });
            } else {
                setErrors({ fatal: 'Something went wrong, please try again' });
            }
        }
    };

    const getListings = async (searchString) => {
        try {
            const response = await axios.get(`${serverEndpoint}/listings/get-listings/${searchString}`, { withCredentials: true });
            const listings = response.data.items;
            return listings.map(item => ({
                value: item.sk.split('L#')[1],
                label: item.title,
                imageKey: item.image_key,
                imageUrl: item.image_url,
                salePrice: item.sale_price
            }));
        } catch (error) {
            console.log(error);
            setErrors({ message: 'Something went wrong, please try again!' });
            return [];
        }
    };

    const getComponents = async (searchString) => {
        try {
            const response = await axios.get(`${serverEndpoint}/components/get-components/${searchString}`, { withCredentials: true });
            return response.data.items.map(item => ({
                value: item.sk.split('C#')[1],
                label: item.title,
                imageKey: item.image_key,
                imageUrl: item.image_url,
                quantity: 1
            }));
        } catch (error) {
            console.log(error);
            setErrors({ message: 'Something went wrong, please try again!' });
            return [];
        }
    };

    useEffect(() => {
        const fetchData = async () => {
            try {
                setLoading(true);
                await getBundle();
            } catch (error) {
                console.log(error);
                setErrors({ fatal: 'An error occurred while fetching data!' });
            } finally {
                setLoading(false);
            }
        };

        fetchData();
    }, []);

    const fetchOptionsWithDelay = useCallback((type) => (inputValue, callback) => {
        if (timeoutId) {
            clearTimeout(timeoutId);
        }

        const newTimeoutId = setTimeout(async () => {
            if (!inputValue) return;

            try {
                let items = [];
                if (type === 'LISTING') {
                    items = await getListings(inputValue);
                } else if (type === 'COMPONENT') {
                    items = await getComponents(inputValue);
                }
                callback(items);
            } catch (error) {
                console.log(error);
                callback([]);
            }
        }, 500);

        setTimeoutId(newTimeoutId);
    }, [timeoutId]);

    const handleQuantityChange = (itemValue, quantity) => {
        setFormData((prevFormData) => ({
            ...prevFormData,
            components: prevFormData.components.map((component) =>
                component.value === itemValue ? { ...component, quantity: quantity } : component
            ),
        }));
    };

    if (loading) {
        return <Spinner />;
    }

    if (errors.fatal) {
        return <ErrorComponent />;
    }

    if (errors?.notFound) {
        return <NotFound404 />;
    }

    return (
        <div className="container">
            <div className="row mt-4 p-3 bg-white rounded border">
                {errors.message && (
                    <div className="alert alert-danger" role="alert">
                        {errors.message}
                    </div>
                )}
                {message && (
                    <div className="alert alert-success" role="alert">
                        {message}
                    </div>
                )}
                {isEdit ? (
                    <div>
                        <input id="title" type="text" className={errors?.title ? 'form-control is-invalid' : 'form-control'}
                            name="title" value={formData.title} required onChange={handleChange}
                        />
                        <div className="invalid-feedback">{errors?.title}</div>
                    </div>
                ) : (
                    <h2 className="mb-3">{bundleData.title}</h2>
                )}
                <div className="mb-3">
                    {!isEdit && (
                        <>
                            <button className="btn btn-outline-primary btn-sm me-2" onClick={handleEdit}>
                                <MdModeEdit className="mb-1" /> Edit
                            </button>
                            <button className="btn btn-outline-danger btn-sm" onClick={handleDelete}>
                                <MdDelete className="mb-1" /> Delete
                            </button>
                        </>
                    )}

                    {isEdit && (
                        <div className="pt-2">
                            {formLoading ? (
                                <>
                                    <button className="btn btn-primary btn-sm me-2" type="button" disabled="">
                                        <span
                                            className="spinner-border spinner-border-sm"
                                            role="status"
                                            aria-hidden="true"
                                        />
                                    </button>
                                </>
                            ) : (
                                <>
                                    <button className="btn btn-primary btn-sm me-2" onClick={handleSubmit}>
                                        <IoMdSave className="mb-1" /> Save
                                    </button>
                                </>
                            )}
                            <button className="btn btn-warning text-white btn-sm" onClick={() => updateBundleData({}, false)}>
                                <MdDelete className="mb-1" /> Close
                            </button>

                        </div>
                    )}

                </div>
                <div className="col-12 col-lg-6 mb-4">
                    {previewImageUrl ? (
                        <img src={previewImageUrl} alt="Preview" style={{
                            maxHeight: '500px',
                            width: '100%',
                            objectFit: 'cover'
                        }} />
                    ) : (
                        <div className="row">
                            <img src={bundleData.image_url ? bundleData.image_url : '/images/default-image.jpg'}
                                onError={(e) => {
                                    e.target.onerror = null; // Prevent infinite loop if fallback image fails
                                    e.target.src = "/images/default-image.jpg";
                                }}
                                alt={bundleData.title} className="rounded" style={{
                                    maxHeight: '500px',
                                    width: '100%',
                                    objectFit: 'cover'
                                }} />
                        </div>
                    )}

                    {isEdit && (
                        <div className="row mt-1 mx-1 pt-2">
                            <input id="bundleImage" type="file" name="bundleImage"
                                className={errors.bundleImage ? 'form-control is-invalid' : 'form-control'}
                                onChange={handleImageChange} accept="image/*"
                            />
                            <div className="invalid-feedback">{errors?.bundleImage}</div>
                        </div>
                    )}
                </div>
                <div className="col-12 col-lg-6">
                    <table className="table">
                        <tbody>
                            <tr>
                                <th className="w-25">Description</th>
                                <td>
                                    {isEdit ? (
                                        <div>
                                            <input id="description" type="text" className={errors?.description ? 'form-control is-invalid' : 'form-control'}
                                                name="description" value={formData.description} required onChange={handleChange}
                                            />
                                            <div className="invalid-feedback">{errors?.description}</div>
                                        </div>
                                    ) : (
                                        <>{bundleData.description}</>
                                    )}
                                </td>
                            </tr>
                            <tr>
                                <th className="w-25">Instock Qty</th>
                                <td>
                                    {isEdit ? (
                                        <div>
                                            <input id="instockQuantity" type="number" min="0" className={errors?.instockQuantity ? 'form-control is-invalid' : 'form-control'}
                                                name="instockQuantity" value={formData.instockQuantity} required autoFocus onChange={handleChange}
                                            />
                                            <div className="invalid-feedback">Please provide a valid Instock Quantity</div>
                                        </div>
                                    ) : (
                                        <>{bundleData.instock_quantity}</>
                                    )}
                                </td>
                            </tr>
                            <tr>
                                <th className="w-25">Sale Price</th>
                                <td>
                                    {isEdit ? (
                                        <div>
                                            <input id="salePrice" type="number" step="0.01" min="0" className={errors?.salePrice ? 'form-control is-invalid' : 'form-control'}
                                                name="salePrice" value={formData.salePrice} required onChange={handleChange}
                                            />
                                            <div className="invalid-feedback">Please provide a Sale Price</div>
                                        </div>
                                    ) : (
                                        <>{currencySymbol}{bundleData.sale_price}</>
                                    )}
                                </td>
                            </tr>
                            <tr>
                                <th className="w-25">COG</th>
                                <td>
                                    {isEdit ? (
                                        <div>
                                            <input id="cog" type="number" step="0.01" min="0" className={errors?.cog ? 'form-control is-invalid' : 'form-control'}
                                                name="cog" value={formData.cog} required onChange={handleChange}
                                            />
                                            <div className="invalid-feedback">Please provide a valid COG</div>
                                        </div>
                                    ) : (
                                        <>{currencySymbol}{bundleData.cog}</>
                                    )}
                                </td>
                            </tr>
                            <tr>
                                <th className="w-25">Margin (%)</th>
                                <td>
                                    {isEdit ? (
                                        <div>
                                            <input id="margin" type="number" step="0.01" min="0" className={errors?.margin ? 'form-control is-invalid' : 'form-control'}
                                                name="margin" value={formData.margin} onChange={handleChange} disabled
                                            />
                                            <div className="invalid-feedback">Please provide a valid Margin</div>
                                        </div>
                                    ) : (
                                        <>{bundleData.cog}%</>
                                    )}
                                </td>
                            </tr>
                            <tr>
                                <th className="w-25">ROI (%)</th>
                                <td>
                                    {isEdit ? (
                                        <div>
                                            <input id="roi" type="number" step="0.01" min="0" className={errors?.roi ? 'form-control is-invalid' : 'form-control'}
                                                name="roi" value={formData.roi} onChange={handleChange} disabled
                                            />
                                            <div className="invalid-feedback">Please provide a valid ROI</div>
                                        </div>
                                    ) : (
                                        <>{bundleData.roi}%</>
                                    )}
                                </td>
                            </tr>
                            <tr>
                                <th className="w-25">Profit</th>
                                <td>
                                    {isEdit ? (
                                        <div>
                                            <input id="profit" type="number" step="0.01" min="0" className={errors?.profit ? 'form-control is-invalid' : 'form-control'}
                                                name="profit" value={formData.profit} onChange={handleChange}
                                            />
                                            <div className="invalid-feedback">Please provide a valid Profit</div>
                                        </div>
                                    ) : (
                                        <>{currencySymbol}{bundleData.profit}</>
                                    )}
                                </td>
                            </tr>
                        </tbody>
                    </table>
                </div>
            </div >


            <div className="row mt-4 p-3 bg-white rounded border">
                <h3>Associated Listing</h3>
                {isEdit ? (
                    <div className="mb-3">
                        <label className="mb-2 text-muted" htmlFor="listings">
                            Listing
                        </label>
                        <AsyncSelect
                            cacheOptions
                            loadOptions={fetchOptionsWithDelay("LISTING")}
                            defaultOptions={[]}
                            value={formData.listings}
                            onChange={handleListingChange}
                            placeholder="Search for Listing..."
                            noOptionsMessage={() => "No Listings found"}
                            className={errors.itemToPrepare ? 'is-invalid' : ''}
                            classNamePrefix="react-select"
                            styles={{
                                control: (base, state) => ({
                                    ...base,
                                    borderColor: errors.itemToPrepare ? '#dc3545' : base.borderColor,
                                    '&:hover': {
                                        borderColor: errors.itemToPrepare ? '#dc3545' : base.borderColor,
                                    },
                                }),
                            }}
                            components={{ Option: CustomOption }}
                        />
                        <div className="invalid-feedback">{errors?.listings}</div>
                    </div>
                ) : (
                    <div className="col">
                        {bundleData.listings && Object.keys(bundleData.listings).length > 0 ? (
                            <Row className="mt-4">
                                {Object.entries(bundleData.listings).map(([listingId, listing]) => (
                                    <Col xs={12} md={6} lg={3} key={listingId} className="mb-4">
                                        <Card className="h-100">
                                            <Link to={`/listing/${listingId}`}>
                                                <Card.Img
                                                    variant="top"
                                                    src={listing.image_url ? listing.image_url : '/images/default-image.jpg'}
                                                    alt={listing.title}
                                                    style={{ height: '200px', objectFit: 'cover' }}
                                                />
                                            </Link>

                                            <Card.Body>
                                                <Link to={`/listing/${listingId}`} className="text-body text-decoration-none hover:text-primary">
                                                    <Card.Title>{listing.title}</Card.Title>
                                                </Link>
                                            </Card.Body>
                                        </Card>
                                    </Col>
                                ))}
                            </Row>
                        ) : (
                            <p className="text-muted">No Listings available for this Bundle.</p>
                        )}
                    </div>
                )}

            </div>

            <div className="row mt-4 mb-4 p-2 bg-white rounded">
                <h3>Associated Components</h3>
                {isEdit ? (
                    <>
                        <div className="mb-3">
                            <label className="mb-2 text-muted" htmlFor="components">
                                Components
                            </label>
                            <AsyncSelect
                                cacheOptions
                                isMulti
                                loadOptions={fetchOptionsWithDelay("COMPONENT")}
                                defaultOptions={[]}
                                value={formData.components}
                                onChange={handleComponentsChange}
                                placeholder="Search for Component..."
                                noOptionsMessage={() => "No Component found"}
                                className={errors.components ? 'is-invalid' : ''}
                                classNamePrefix="react-select"
                                styles={{
                                    control: (base, state) => ({
                                        ...base,
                                        borderColor: errors.itemToPrepare ? '#dc3545' : base.borderColor,
                                        '&:hover': {
                                            borderColor: errors.itemToPrepare ? '#dc3545' : base.borderColor,
                                        },
                                    }),
                                }}
                            />
                        </div>
                        {formData.components.length > 0 && (
                            <div className="mb-3">
                                <label className="mb-2 text-muted" htmlFor="bundleImage">
                                    Component Quantity
                                </label>
                                <div className='container border rounded p-2'>
                                    {formData.components.map((item) => (
                                        <div key={item.value} className='mb-2'>
                                            <label className="text-muted" htmlFor={item.value}>
                                                {item.label}
                                            </label>
                                            <input
                                                id={item.value}
                                                name={item.value}
                                                type="number"
                                                value={item.quantity}
                                                onChange={(e) => handleQuantityChange(item.value, e.target.value)}
                                                placeholder="Enter quantity"
                                                className={errors.components?.[item.value] ? 'form-control is-invalid' : 'form-control'}
                                            />
                                            <div className="invalid-feedback">{errors?.components?.[item.value]}</div>
                                        </div>
                                    ))}
                                </div>
                            </div>
                        )}
                    </>
                ) : (
                    <div className="col">
                        {bundleData.components && Object.keys(bundleData.components).length > 0 ? (
                            <Row className="mt-4">
                                {Object.entries(bundleData.components).map(([componentId, component]) => (
                                    <Col xs={12} md={6} lg={3} key={componentId} className="mb-4">
                                        <Card className="h-100">
                                            <Link to={`/component/${componentId}`}>
                                                <Card.Img
                                                    variant="top"
                                                    src={component.image_url ? component.image_url : '/images/default-image.jpg'}
                                                    alt={component.title}
                                                    style={{ height: '200px', objectFit: 'cover' }}
                                                />
                                            </Link>
                                            <Card.Body>
                                                <Link to={`/component/${componentId}`} className="text-body text-decoration-none hover:text-primary">
                                                    <Card.Title>{component.title}</Card.Title>
                                                </Link>
                                                <p className="text-muted">Quantity: {component.quantity || 1}</p>
                                            </Card.Body>
                                        </Card>
                                    </Col>
                                ))}
                            </Row>
                        ) : (
                            <p className="text-muted">No Components available for this Bundle.</p>
                        )}
                    </div>
                )}

            </div>

            <ConfirmDialog show={showConfirmDeleteModal} handleClose={handleConfirmDeleteModalClose}
                title="Delete Listing Confirmation"
                message="Are you sure you want to delete the listing?"
            />
        </div >

    );
}

export default ViewBundle;