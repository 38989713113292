import React from 'react';
import { useSelector, useDispatch } from 'react-redux';
import {
    CCloseButton, CSidebar, CSidebarBrand, CSidebarHeader,
    CSidebarNav, CNavTitle, CNavItem, CNavLink, CNavGroup
} from '@coreui/react';
import { TbHexagonLetterJ } from "react-icons/tb";
import { NavLink } from 'react-router-dom';
import { BsTelephoneInbound } from "react-icons/bs";
import { AiOutlineUserAdd } from "react-icons/ai";
import { FaUsersViewfinder } from "react-icons/fa6";
import { MdAddBusiness } from "react-icons/md";
import { LuComponent, LuListTodo } from "react-icons/lu";
import { LiaSitemapSolid } from "react-icons/lia";
import { CiViewList } from "react-icons/ci";
import { GrBundle } from "react-icons/gr";
import { FaUsers, FaTasks } from "react-icons/fa";
import { MdOutlineInventory } from "react-icons/md";
import { RiFileExcel2Fill } from "react-icons/ri";
import { IoCheckmarkDoneCircleOutline } from "react-icons/io5";

function AppSidebar() {
    const dispatch = useDispatch()
    const unfoldable = useSelector((state) => state.sidebarUnfoldable)
    const sidebarShow = useSelector((state) => state.sidebarShow)

    return (
        <CSidebar
            className="border-end"
            colorScheme="dark"
            position="fixed"
            unfoldable={unfoldable}
            visible={sidebarShow}
            onVisibleChange={(visible) => {
                dispatch({ type: 'set', sidebarShow: visible })
            }}
        >
            <CSidebarHeader className="border-bottom">
                <CSidebarBrand className='dropdown-item' as={NavLink} to="/">
                    <TbHexagonLetterJ size={24} className="mb-1 mx-1" />
                    JBL Prep Master
                </CSidebarBrand>
                <CCloseButton
                    className="d-lg-none"
                    dark
                    onClick={() => dispatch({ type: 'set', sidebarShow: false })}
                />
            </CSidebarHeader>
            <CSidebarNav>
                <CNavTitle>Products</CNavTitle>
                <CNavGroup
                    toggler={
                        <>
                            <LiaSitemapSolid size={20} className='me-2' /> Listings
                        </>
                    }
                >
                    <CNavLink to="/listings" as={NavLink}>
                        <CiViewList size={20} className='me-2' /> View Listings
                    </CNavLink>
                </CNavGroup>

                <CNavGroup
                    toggler={
                        <>
                            <LuComponent size={20} className='me-2' /> Components
                        </>
                    }
                >
                    <CNavLink to="/add-component" as={NavLink}>
                        <MdAddBusiness size={20} className='me-2' /> Add Component
                    </CNavLink>
                    <CNavLink to="/components" as={NavLink}>
                        <CiViewList size={20} className='me-2' /> View Components
                    </CNavLink>
                </CNavGroup>

                <CNavGroup
                    toggler={
                        <>
                            <GrBundle size={20} className='me-2' /> Bundles
                        </>
                    }
                >
                    <CNavLink to="/bundle/add" as={NavLink}>
                        <MdAddBusiness size={20} className='me-2' /> Add Bundle
                    </CNavLink>
                    <CNavLink to="/bundles" as={NavLink}>
                        <CiViewList size={20} className='me-2' /> View Bundles
                    </CNavLink>
                </CNavGroup>


                <CNavTitle>Inventory</CNavTitle>
                <CNavItem>
                    <CNavLink to="/inbound" as={NavLink}>
                        <BsTelephoneInbound size={20} className='me-2' /> Inbound Items
                    </CNavLink>
                </CNavItem>
                <CNavItem>
                    <CNavLink to="/instock" as={NavLink}>
                        <MdOutlineInventory size={20} className='me-2' /> Instock Items
                    </CNavLink>
                </CNavItem>


                <CNavTitle>Tasks</CNavTitle>
                <CNavItem>
                    <CNavLink to="/task/add" as={NavLink}>
                        <LuListTodo size={20} className='me-2' /> Create Task
                    </CNavLink>
                </CNavItem>
                <CNavItem>
                    <CNavLink to="/tasks" as={NavLink}>
                        <FaTasks size={20} className='me-2' /> View Tasks
                    </CNavLink>
                </CNavItem>
                <CNavItem>
                    <CNavLink to="/task-history" as={NavLink}>
                        <IoCheckmarkDoneCircleOutline size={20} className='me-2' /> Task History
                    </CNavLink>
                </CNavItem>
                
                <CNavTitle>Reports</CNavTitle>
                <CNavItem>
                    <CNavLink to="/reports" as={NavLink}>
                        <RiFileExcel2Fill size={20} className='me-2' /> Prepared Items
                    </CNavLink>
                </CNavItem>
                
                <CNavTitle>Account</CNavTitle>
                <CNavGroup
                    toggler={
                        <>
                            <FaUsers size={20} className='me-2' /> Manage Users
                        </>
                    }
                >
                    <CNavLink to="/users/add-user" as={NavLink}>
                        <AiOutlineUserAdd size={20} className='me-2' /> Add User
                    </CNavLink>
                    <CNavLink to="/users/view-all" as={NavLink}>
                        <FaUsersViewfinder size={20} className='me-2' /> View Users
                    </CNavLink>
                </CNavGroup>
            </CSidebarNav>
        </CSidebar>
    )
}

export default AppSidebar;
