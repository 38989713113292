import { useState } from "react";
import { Link } from "react-router-dom";
import axios from 'axios';
import { serverEndpoint } from "../../util/config";
import MultiFactorAuthentication from "./MultiFactorAuthentication";
import VerifyEmail from "./VerifyEmail";
import triggerVerifyEmail from "../../util/triggerVerifyEmail";
import { TbHexagonLetterJ } from "react-icons/tb";
import ChangePassword from "./ChangePassword";

function Signin({ updateUserDetails }) {
    const [mfaDetails, setMfaDetails] = useState(null);
    const [changePasswordDetails, setChangePasswordDetails] = useState(null);
    const [verifyEmailDetails, setVerifyEmailDetails] = useState(null);

    const [loading, setLoading] = useState(false);
    const [formData, setFormData] = useState({
        email: '',
        password: ''
    });

    const [errors, setErrors] = useState({});

    const validateForm = () => {
        let isValid = true;
        const newErrors = {};

        if (formData.email.trim() === '') {
            newErrors.username = 'Please provide a valid username';
            isValid = false;
        }

        if (formData.password.trim() === '') {
            newErrors.password = 'Please provide a valid password';
            isValid = false;
        }

        setErrors(newErrors);
        return isValid;
    }

    const handleChange = (e) => {
        setFormData({
            ...formData,
            [e.target.name]: e.target.value
        });
    };

    const handleSubmit = async (e) => {
        e.preventDefault();
        if (validateForm()) {
            setLoading(true);
            try {
                const response = await axios.post(`${serverEndpoint}/auth/signin`, {
                    email: formData.email,
                    password: formData.password
                }, { withCredentials: true });
                if (response.data.message && response.data.message === "MFA_REQUIRED") {
                    setMfaDetails({
                        username: formData.email,
                        session: response.data.session
                    });
                } else if (response.data.message && response.data.message === "EMAIL_VERIFICATION_REQUIRED") {
                    await triggerVerifyEmail(formData.email);
                    setVerifyEmailDetails({
                        email: formData.email,
                        password: formData.password
                    });
                } else if (response.data?.message === "NEW_PASSWORD_REQUIRED") {
                    setChangePasswordDetails({
                        username: formData.email,
                        session: response.data.session
                    });
                } else {
                    const userDetails = response.data.user;
                    userDetails.isFirstLogin = userDetails.featureFlags.welcome_notifications ? true : false;
                    updateUserDetails(userDetails);
                }
                setLoading(false);
            } catch (error) {
                console.log(error);
                if (error.response.data.error && error.response.data.error === "Incorrect username or password.") {
                    setErrors({
                        message: "Incorrect email or password"
                    });
                }

                if (error.response.data.error && error.response.data.error === "Password attempts exceeded") {
                    setErrors({
                        message: "Password attempts exceeded, please try after sometime"
                    });
                }

                if (error.response.data.error && error.response.data.error === "User is disabled.") {
                    setErrors({
                        message: "Account disabled, contact your admin"
                    });
                }

                setLoading(false);
            }
        }
    };

    if (mfaDetails) {
        return (
            <MultiFactorAuthentication userDetails={mfaDetails} updateUserDetails={updateUserDetails} />
        );
    }

    if (changePasswordDetails) {
        return (
            <ChangePassword userDetails={changePasswordDetails} updateUserDetails={updateUserDetails} />
        );
    }

    if (verifyEmailDetails) {
        return (
            <VerifyEmail userDetails={verifyEmailDetails} updateUserDetails={updateUserDetails} />
        );
    }

    return (
        <section className="h-100">
            <div className="container h-100">
                <div className="row justify-content-sm-center h-100">
                    <div className="col-xxl-4 col-xl-5 col-lg-5 col-md-7 col-sm-9">
                        <div className="card shadow-lg">
                            <div className="text-center mt-2">
                                <TbHexagonLetterJ size={90} className="text-primary" />
                            </div>
                            <div className="card-body p-4">
                                <h1 className="fs-4 card-title fw-bold mb-4">Sign in to Continue</h1>
                                {errors.message && (
                                    <div className="alert alert-danger" role="alert">
                                        {errors.message}
                                    </div>
                                )}
                                <form onSubmit={handleSubmit}>
                                    <div className="mb-3">
                                        <label className="mb-2 text-muted" htmlFor="email">
                                            Email
                                        </label>
                                        <input id="email" type="email" className={errors.username ? 'form-control is-invalid' : 'form-control'}
                                            name="email" defaultValue="" required="" autoFocus onChange={handleChange}
                                        />
                                        <div className="invalid-feedback">Please provide a valid email</div>
                                    </div>
                                    <div className="mb-3">
                                        <div className="mb-2 w-100">
                                            <label className="text-muted" htmlFor="password">
                                                Password
                                            </label>
                                        </div>
                                        <input id="password" type="password" className={errors.password ? 'form-control is-invalid' : 'form-control'}
                                            name="password" required="" onChange={handleChange}
                                        />
                                        <div className="invalid-feedback">Please provide a valid password</div>
                                    </div>
                                    <div className="d-flex align-items-center">
                                        <Link to="/forgot-password" className="float-end">
                                            Forgot Password?</Link>
                                        {loading && (
                                            <button className="btn btn-primary ms-auto" type="button" disabled="">
                                                <span
                                                    className="spinner-border spinner-border-sm"
                                                    role="status"
                                                    aria-hidden="true"
                                                />
                                            </button>
                                        )}

                                        {!loading && (
                                            <button className="btn btn-primary ms-auto" type="submit">
                                                Login
                                            </button>
                                        )}
                                    </div>
                                </form>
                            </div>
                            <div className="card-footer py-3 border-0">
                                <div className="text-center">
                                    Don't have an account?{" "}
                                    <Link to="/signup" className="text-dark">
                                        Sign up
                                    </Link>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </section>
    );
}

export default Signin;