import { useState } from "react";
import axios from 'axios';
import { serverEndpoint } from "../../util/config";

function ChangePassword({ userDetails, updateUserDetails }) {
    const [loading, setLoading] = useState(false);
    const [formData, setFormData] = useState({
        newPassword: '',
        confirmNewPassword: ''
    });

    const [message, setMessage] = useState(null);
    const [errors, setErrors] = useState({});

    const handleChange = (e) => {
        setFormData({
            ...formData,
            [e.target.name]: e.target.value
        });
    };

    const validateForm = () => {
        let isValid = true;
        const newErrors = {};

        const passwordRegex = /^(?!.*\s{2,})(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[\s!@#$%^&*(),.?":{}|<>])[A-Za-z\d\s!@#$%^&*(),.?":{}|<>]{8,}$/;
        if (!passwordRegex.test(formData.newPassword)) {
            newErrors.newPassword = 'Please provide a valid Password';
            isValid = false;
        }

        if (formData.newPassword !== formData.confirmNewPassword) {
            newErrors.confirmNewPassword = 'Passwords must match';
            isValid = false;
        }

        setErrors(newErrors);
        return isValid;
    }

    const handleSubmit = async (e) => {
        e.preventDefault();
        if (validateForm()) {
            setLoading(true);
            axios.post(`${serverEndpoint}/auth/signin`, {
                session: userDetails.session,
                email: userDetails.username,
                newPassword: formData.newPassword
            }, { withCredentials: true })
                .then(response => {
                    setLoading(false);
                    const userDetails = response.data.user;
                    userDetails.isFirstLogin = userDetails.featureFlags.welcome_notifications ? true : false;
                    updateUserDetails(userDetails);
                }).catch(error => {
                    console.log(error);
                    setMessage("Unable to change password, please try again");
                    setLoading(false);
                });
        }
    };

    return (
        <div className="row justify-content-center mt-7">
            <div className="col-lg-4 ">
                <div className="card mt-5">
                    <div className="card-body py-5 px-lg-5">
                        <div>
                            {message && (
                                <div className="alert alert-danger" role="alert">
                                    {message}
                                </div>
                            )}
                        </div>
                        <h3 className="fw-normal text-dark mt-4 text-center">Change Password</h3>
                        <p className="mb-3 text-center">
                            Please enter your new password below.
                        </p>
                        <form onSubmit={handleSubmit}>
                            <div className="mb-3">
                                <label className="mb-2 text-muted" htmlFor="newPassword">
                                    New Password
                                </label>
                                <input id="newPassword" type="password" className={errors.newPassword ? 'form-control is-invalid' : 'form-control'}
                                    name="newPassword" onChange={handleChange} value={formData.newPassword}
                                />
                                <div className="invalid-feedback">Password must be at least 8 characters long and contain lowercase, uppercase, number, and special character/space, without leading or trailing spaces.</div>
                            </div>
                            <div className="mb-3">
                                <label className="mb-2 text-muted" htmlFor="confirmNewPassword">
                                    Confirm New Password
                                </label>
                                <input id="confirmNewPassword" type="password" className={errors.confirmNewPassword ? 'form-control is-invalid' : 'form-control'}
                                    name="confirmNewPassword" onChange={handleChange} value={formData.confirmNewPassword}
                                />
                                <div className="invalid-feedback">Passwords must match.</div>
                            </div>
                            <div className="d-flex align-items-center">
                                {loading && (
                                    <button className="btn btn-primary ms-auto" type="button" disabled="">
                                        <span
                                            className="spinner-border spinner-border-sm"
                                            role="status"
                                            aria-hidden="true"
                                        />
                                    </button>
                                )}

                                {!loading && (
                                    <button className="btn btn-primary ms-auto" type="submit">
                                        Submit
                                    </button>
                                )}
                            </div>
                        </form>
                    </div>
                </div>
            </div>
        </div>
    );
}

export default ChangePassword;