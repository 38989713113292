import axios from 'axios';
import { useState } from 'react';
import Modal from 'react-bootstrap/Modal';
import { useSelector } from 'react-redux';
import { useDispatch } from "react-redux";
import { Link } from 'react-router-dom';
import { serverEndpoint } from '../../util/config';

function WelcomeScreen() {
    const dispatch = useDispatch();
    const userDetails = useSelector((state) => state.userDetails);
    const [modalShow, setModalShow] = useState(true);
    const [isAcknowledging, setIsAcknowledging] = useState(false);
    const [errors, setErrors] = useState({});

    const handleHide = () => {
        delete userDetails.isFirstLogin;
        dispatch({ type: 'setUserDetails', userDetails: userDetails });
        setModalShow(false);
    };

    const handleAcknowledge = async () => {
        try {
            setIsAcknowledging(true);
            await axios.post(`${serverEndpoint}/feature-flags/update`, {
                updated_feature_key: 'welcome_notifications',
                updated_feature_value: false
            }, { withCredentials: true });
            delete userDetails.isFirstLogin;
            dispatch({ type: 'setUserDetails', userDetails: userDetails });
            setModalShow(false);
        } catch (error) {
            setErrors({ message: 'Unable to save your customization preference, please try again' });
            console.log(error);
        } finally {
            setIsAcknowledging(false);
        }
    };

    return (
        <Modal
            aria-labelledby="contained-modal-title-vcenter"
            centered
            show={modalShow}
            onHide={handleHide}
        >
            <Modal.Body>
                {errors.message && (
                    <div className={`alert alert-danger'} `} role="alert">
                        {errors.message}
                    </div>
                )}
                <h4>Welcome to JBL Prep Master</h4>
                <hr className='' />
                <p>
                    🚀 Quickly familiarize yourself with JBL Prep Master’s key features by watching the tutorial videos {' '}
                    <Link to='https://www.jblsoftware.com/all-softwares/jbl-prep-master#tutorials' target="_blank">
                        <strong>here</strong>
                    </Link>. 📹 These videos are also accessible via the footer at the bottom of the page.
                    <br />
                    <br />
                    ❎ You can close this screen by clicking the <strong className='text-primary'>Close</strong> button.
                    <span> 🔁 Note: If closed, it will reappear on your next login!</span>
                    <br />
                    <br />
                    ✅ To stop seeing this screen, click <strong className='text-warning'>Acknowledge</strong>.
                </p>
                <button className='btn-close' onClick={handleHide} style={{ position: 'absolute', top: '20px', right: '20px' }} />
            </Modal.Body>
            <Modal.Footer>
                {isAcknowledging && (
                    <>
                        <button className='btn btn-primary' onClick={handleHide} disabled="">
                            Close
                        </button>
                        <button className="btn btn-warning text-white ms-2" type="button" disabled="">
                            <span
                                className="spinner-border spinner-border-sm"
                                role="status"
                                aria-hidden="true"
                            />
                        </button>
                    </>
                )}

                {!isAcknowledging && (
                    <>
                        <button className='btn btn-primary' onClick={handleHide}>
                            Close
                        </button>
                        <button className='btn btn-warning text-white ms-2' onClick={handleAcknowledge}>
                            Acknowledge
                        </button>
                    </>
                )}
            </Modal.Footer>
        </Modal>
    );
}

export default WelcomeScreen;