import React from 'react';
import { Container, Row, Col } from 'react-bootstrap';

function NotFound404() {
    return (
        <Container className="text-center my-5">
            <Row className="justify-content-center">
                <Col md={6}>
                    <h1 className="display-4 text-danger">404</h1>
                    <p className="text-muted">Requested resource not found. Either it doesn't exist or has been deleted.</p>
                </Col>
            </Row>
        </Container>
    );
}

export default NotFound404;