import React from 'react'
import { Link } from 'react-router-dom';

function AppFooter() {
    return (
        <>
            <div className="">
                <footer className="py-1 mt-4 bg-white shadow">
                    <ul className="nav justify-content-center border-bottom pb-2 mb-2">
                        <li className="nav-item">
                            <Link to="/dashboard" className="nav-link px-2 text-muted">
                                Dashboard
                            </Link>
                        </li>
                        <li className="nav-item">
                            <Link to="https://www.jblsoftware.com/all-softwares/jbl-prep-master#tutorials" target="_blank" className="nav-link px-2 text-muted">
                                Tutorials
                            </Link>
                        </li>
                        <li className="nav-item">
                            <Link to="https://www.jblsoftware.com/all-terms-and-conditions/jbl-prep-master" target="_blank" className="nav-link px-2 text-muted">
                                Terms & Conditions
                            </Link>
                        </li>
                        <li className="nav-item">
                            <Link to="https://www.jblsoftware.com/all-privacy-policies/jbl-prep-master" target="_blank" className="nav-link px-2 text-muted">
                                Privacy Policy
                            </Link>
                        </li>
                        <li className="nav-item">
                            <Link to="https://www.jblsoftware.com/extras/contact-us" target="_blank" className="nav-link px-2 text-muted">
                                Contact Us
                            </Link>
                        </li>
                    </ul>
                    <p className="text-center text-muted">
                        JBL Prep Master © 2024 | Powered by:
                        <Link to="https://www.jblsoftware.com/" target="_blank" className="btn-link mx-1 text-muted">
                            JBL Software
                        </Link>
                    </p>
                </footer>
            </div>
        </>
    )
}

export default AppFooter;
